// src/api.ts

import axios, { AxiosError } from 'axios';

// APIのベースURL（環境変数から取得）
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

// API呼び出しの共通関数
export const callApi = async (endpoint: string, data: any, token: string) => {
  try {
    const result = await axios.post(`${API_BASE_URL}${endpoint}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        throw new Error(`エラー: ${axiosError.response.status} - ${axiosError.response.data}`);
      } else if (axiosError.request) {
        throw new Error('エラー: サーバーからの応答がありません。');
      } else {
        throw new Error(`エラー: ${axiosError.message}`);
      }
    } else {
      throw new Error('予期せぬエラーが発生しました。');
    }
  }
};

// テストAPI
export const testApi = (data: any, token: string) => callApi('/chatbot/test', data, token);

// LLM API
export const llmApi = (data: any, token: string) => callApi('/chatbot/askLlm', data, token);

// RAG API
export const ragApi = (data: any, token: string) => callApi('/chatbot/askRag', data, token);

// 型定義
export interface ApiRequest {
  input: string;
  temperature?: number;
  max_tokens?: number;
  bedrock_model_id?: string;
  top_p?: number;
  sessionId?: string;
}

export interface ApiResponse {
  message?: string;
  answer?: string;
}
