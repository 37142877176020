import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';

const SignUpPasswordForm: React.FC<{
  onCompleteSignUp: (password: string) => void;
}> = ({ onCompleteSignUp }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onCompleteSignUp(password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="パスワード"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        サインアップを完了
      </Button>
    </form>
  );
};

export default SignUpPasswordForm;
