import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField, Button, Paper, Typography, Container, Box,
  CircularProgress, Grid, IconButton, Drawer
} from '@mui/material';
import Markdown from 'markdown-to-jsx';
import backgroundImage from './assets/images/bluesea.jpeg';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PersonIcon from '@mui/icons-material/Person';
import { CognitoUser } from 'amazon-cognito-identity-js';
import SignInForm from './components/SignInForm';
import SignUpEmailForm from './components/SignUpEmailForm';
import SignUpCodeForm from './components/SignUpCodeForm';
import SignUpPasswordForm from './components/SignUpPasswordForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import ParameterSettings from './components/ParameterSettings';
import ModeSelector from './components/ModeSelector';
import { v4 as uuidv4 } from 'uuid';
import { testApi } from './api';
import { sendChatMessage } from './services/chatService';
import * as authService from './services/authService';

const APP_NAME = process.env.REACT_APP_APP_NAME as string;

console.log(`Running ${APP_NAME} version ${process.env.REACT_APP_VERSION}`);

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
}

// トークンを管理するカスタムフック
const useAuth = () => {
    const [idToken, setIdToken] = useState<string | null>(() => {
      return localStorage.getItem('idToken');
    });
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);

    const updateToken = useCallback(async () => {
      const currentUser = authService.getCurrentUser();
      if (currentUser) {
        try {
          const session = await authService.getSession(currentUser);
          const newToken = session.getIdToken().getJwtToken();
          setIdToken(newToken);
          localStorage.setItem('idToken', newToken);
          setCognitoUser(currentUser);
          return newToken;
        } catch (error) {
          console.error('Error updating token:', error);
          throw new Error('トークンの更新に失敗しました。再度サインインしてください。');
        }
      }
      const storedToken = localStorage.getItem('idToken');
      if (storedToken) {
        setIdToken(storedToken);
        return storedToken;
      }
      throw new Error('認証されたユーザーが見つかりません。サインインしてください。');
    }, []);

    useEffect(() => {
      const storedToken = localStorage.getItem('idToken');
      if (storedToken) {
        setIdToken(storedToken);
      }
      const currentUser = authService.getCurrentUser();
      if (currentUser) {
        setCognitoUser(currentUser);
      }
    }, []);

    return { idToken, updateToken, cognitoUser, setCognitoUser };
  };

const App: React.FC = () => {
  // 状態変数の定義
  const [testInput, setTestInput] = useState<string>('');
  const [testResponse, setTestResponse] = useState<string>('');
  const [llmInput, setLlmInput] = useState<string>('');
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isTestLoading, setIsTestLoading] = useState<boolean>(false);
  const [isLlmLoading, setIsLlmLoading] = useState<boolean>(false);

  const [temperature, setTemperature] = useState<number>(0.7);
  const [maxTokens, setMaxTokens] = useState<number>(1500);
  const [model, setModel] = useState<string>('anthropic.claude-3-haiku-20240307-v1:0');
  const [topP, setTopP] = useState<number>(0.95);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<'llm' | 'rag' | 'test'>('llm');

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const {idToken, updateToken, cognitoUser, setCognitoUser } = useAuth();
  const [authMode, setAuthMode] = useState<'signIn' | 'signUp' | 'resetPassword'>('signIn');
  const [signUpStage, setSignUpStage] = useState<'email' | 'code' | 'password'>('email');
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpCode, setSignUpCode] = useState('');
  const [generatedUsername, setGeneratedUsername] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string>('');

  // 認証関連の処理
  const handleSignIn = async (email: string, password: string) => {
    setError(null);
    try {
      const session = await authService.signIn(email, password);
      const token = session.getIdToken().getJwtToken();
      setCognitoUser(authService.getCognitoUser(email));
      setIsAuthenticated(true);
      localStorage.setItem('idToken', token);
      localStorage.setItem('email', email);
      await updateToken();
    } catch (error) {
      console.error('Error signing in:', error);
      setError('サインインに失敗しました。');
    }
  };

  const handleSignOut = () => {
    authService.signOut(cognitoUser);
    setCognitoUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem('idToken');
    localStorage.removeItem('email');
  };

  const handleInitiateSignUp = async (email: string) => {
    try {
      const username = await authService.initiateSignUp(email);
      setGeneratedUsername(username);
      setSignUpEmail(email);
      setSignUpStage('code');
      setSuccessMessage('確認コードを送信しました。メールをご確認ください。');
      setError(null);
    } catch (error) {
      console.error('Error initiating sign up:', error);
      setError('確認コードの送信に失敗しました。');
      setSuccessMessage(null);
    }
  };

  const handleConfirmSignUp = async (code: string) => {
    if (!generatedUsername) {
      setError('ユーザー名が見つかりません。サインアッププロセスをやり直してください。');
      return;
    }
    try {
      await authService.confirmSignUp(generatedUsername, code);
      setSignUpCode(code);
      setSignUpStage('password');
      setSuccessMessage('メールアドレスが確認されました。パスワードを設定してください。');
      setError(null);
    } catch (error) {
      console.error('Error confirming sign up:', error);
      setError('確認コードの検証に失敗しました。');
      setSuccessMessage(null);
    }
  };

  const handleCompleteSignUp = async (password: string) => {
    if (!generatedUsername) {
      setError('ユーザー名が見つかりません。サインアッププロセスをやり直してください。');
      return;
    }
    try {
      await handleSignIn(signUpEmail, password);
      setAuthMode('signIn');
      setSuccessMessage('サインアップが完了しました。');
      setError(null);
    } catch (error) {
      console.error('Error completing sign up:', error);
      setError('サインアップの完了に失敗しました。');
      setSuccessMessage(null);
    }
  };

  const handleInitiatePasswordReset = async (email: string) => {
    try {
      await authService.initiatePasswordReset(email);
      setSuccessMessage('確認コードを送信しました。メールをご確認ください。');
      setError(null);
    } catch (error) {
      console.error('Error initiating password reset:', error);
      setError('確認コードの送信に失敗しました。');
    }
  };

  const handleConfirmPasswordReset = async (email: string, code: string, newPassword: string) => {
    try {
      await authService.confirmPasswordReset(email, code, newPassword);
      setAuthMode('signIn');
      setSuccessMessage('パスワードが正常にリセットされました。');
      setError(null);
    } catch (error) {
      console.error('Error confirming password reset:', error);
      setError('パスワードのリセットに失敗しました。');
    }
  };
// App.tsx (後半)

useEffect(() => {
    const storedToken = localStorage.getItem('idToken');
    const storedEmail = localStorage.getItem('email');

    if (storedToken && storedEmail) {
      const cognitoUser = authService.getCognitoUser(storedEmail);

      authService.getSession(cognitoUser)
        .then((session) => {
          if (session.isValid()) {
            setCognitoUser(cognitoUser);
            setIsAuthenticated(true);
          } else {
            handleSignOut();
          }
        })
        .catch((err) => {
          console.error('Error getting session:', err);
          handleSignOut();
        });
    }
  }, []);

  const handleModeChange = (newMode: 'llm' | 'rag' | 'test') => {
    setMode(newMode);
    startNewSession();
  };

  const startNewSession = () => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
    setChatHistory([{
      role: 'assistant',
      content: `新しいセッションを開始しました。現在の${mode === 'rag' ? 'RAG' : mode === 'llm' ? 'LLM' : 'テスト'}モードでお答えします。`
    }]);
  };

  useEffect(() => {
    startNewSession();
  }, [mode]);

  const handleTestSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!testInput.trim()) {
      setTestResponse('警告: 入力が空です。メッセージを入力してください。');
      return;
    }
    setError(null);
    setIsTestLoading(true);
    try {
      const token = await updateToken();
      const result = await testApi({ input: testInput }, token);
      setTestResponse(result.message || '');
    } catch (error) {
      console.error('Error calling test API:', error);
      setError(error instanceof Error ? error.message : '予期せぬエラーが発生しました。');
    } finally {
      setIsTestLoading(false);
    }
    setTestInput('');
  };

  const handleLlmSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!llmInput.trim()) {
      setChatHistory(prev => [...prev, { role: 'assistant', content: '警告: 入力が空です。メッセージを入力してください。' }]);
      return;
    }
    setError(null);
    setIsLlmLoading(true);
    try {
      if (mode === 'test') {
        throw new Error('テストモードではLLMを使用できません。');
      }

      const token = await updateToken();
      const answer = await sendChatMessage({
        input: llmInput,
        temperature,
        maxTokens,
        model,
        topP,
        sessionId,
        mode
      }, token);

      setChatHistory(prev => [
        ...prev,
        { role: 'user', content: llmInput },
        { role: 'assistant', content: answer }
      ]);
    } catch (error) {
      console.error('Error calling API:', error);
      setError(error instanceof Error ? error.message : '予期せぬエラーが発生しました。');
    } finally {
      setIsLlmLoading(false);
    }
    setLlmInput('');
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderAuthForm = () => {
    switch (authMode) {
      case 'signIn':
        return (
          <SignInForm
            onSignIn={handleSignIn}
            onSignUpClick={() => {
              setAuthMode('signUp');
              setSignUpStage('email');
            }}
            onResetPasswordClick={() => setAuthMode('resetPassword')}
          />
        );
      case 'signUp':
        switch (signUpStage) {
          case 'email':
            return (
              <SignUpEmailForm
                onInitiateSignUp={handleInitiateSignUp}
                onSignInClick={() => setAuthMode('signIn')}
              />
            );
          case 'code':
            return (
              <SignUpCodeForm
                onConfirmSignUp={handleConfirmSignUp}
                onResendCode={() => handleInitiateSignUp(signUpEmail)}
              />
            );
          case 'password':
            return (
              <SignUpPasswordForm
                onCompleteSignUp={handleCompleteSignUp}
              />
            );
        }
        break;
      case 'resetPassword':
        return (
          <ResetPasswordForm
            onInitiateReset={handleInitiatePasswordReset}
            onConfirmReset={handleConfirmPasswordReset}
            onSignInClick={() => setAuthMode('signIn')}
          />
        );
    }
  };

  return (
    <Container maxWidth={false} sx={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.95)',
      pt: 8,
    }}>
      <IconButton
        onClick={toggleDrawer}
        sx={{
          position: 'fixed',
          left: 20,
          top: 20,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          color: 'white',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
          zIndex: 1300,
        }}
      >
        <SettingsIcon />
      </IconButton>

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
      >
        <ParameterSettings
          model={model}
          setModel={setModel}
          temperature={temperature}
          setTemperature={setTemperature}
          maxTokens={maxTokens}
          setMaxTokens={setMaxTokens}
          topP={topP}
          setTopP={setTopP}
// App.tsx (後半の続き)

showTest={mode === 'test'}
setShowTest={(show) => setMode(show ? 'test' : 'llm')}
isRagMode={mode === 'rag'}
handleRagModeToggle={(checked) => setMode(checked ? 'rag' : 'llm')}
/>
</Drawer>

<Grid container spacing={2} sx={{ width: '100%', mt: 0, mx: 'auto', maxWidth: '1000px' }}>
<Grid item xs={12}>
<Box sx={{ position: 'relative', mx: 4 }}>
  <Typography variant="h4" component="h1" gutterBottom color="black">
    LLM Chatbot powered by Amazon Bedrock
  </Typography>

  {error && (
    <Paper elevation={3} sx={{ p: 2, mb: 2, bgcolor: 'error.light' }}>
      <Typography variant="body1" color="error" style={{ whiteSpace: 'pre-line' }}>
        {error}
      </Typography>
    </Paper>
  )}

  {successMessage && (
    <Paper elevation={3} sx={{ p: 2, mb: 2, bgcolor: 'success.light' }}>
      <Typography variant="body1" color="success.dark">
        {successMessage}
      </Typography>
    </Paper>
  )}

  {!isAuthenticated ? (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}
    >
      {renderAuthForm()}
    </Box>
  ) : (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleSignOut}
        sx={{ mb: 2 }}
      >
        サインアウト
      </Button>

      <ModeSelector
        mode={mode}
        setMode={handleModeChange}
        startNewSession={startNewSession}
      />

      <Typography variant="subtitle1" gutterBottom color="black" sx={{ mb: 2 }}>
        現在のモデル: {model}
      </Typography>

      {mode === 'test' ? (
        <Box sx={{ mt: 4, p: 2, bgcolor: 'rgba(255, 255, 255, 0.7)', borderRadius: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom color="black">
            テスト用セクション
          </Typography>
          <form onSubmit={handleTestSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              label="テスト用メッセージを入力"
              value={testInput}
              onChange={(e) => setTestInput(e.target.value)}
              margin="normal"
              InputLabelProps={{ style: { color: 'black' } }}
              InputProps={{ style: { color: 'black' } }}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isTestLoading}
              sx={{ color: 'white', mb: 2 }}
            >
              {isTestLoading ? <CircularProgress size={24} /> : 'テスト送信'}
            </Button>
          </form>
          <Paper elevation={3} sx={{ p: 2, bgcolor: 'rgba(255, 255, 255, 0.5)' }}>
            <Typography variant="body1" color="black">{testResponse || 'ここにテスト用の応答が表示されます。'}</Typography>
          </Paper>
        </Box>
      ) : (
        <>
          <form onSubmit={handleLlmSubmit}>
            <TextField
              fullWidth
              variant="outlined"
              label="生成AIへの質問を入力"
              value={llmInput}
              onChange={(e) => setLlmInput(e.target.value)}
              margin="normal"
              InputLabelProps={{ style: { color: 'black' } }}
              InputProps={{ style: { color: 'black' } }}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              variant="contained"
              color={mode === 'rag' ? "warning" : "secondary"}
              fullWidth
              disabled={isLlmLoading}
              sx={{ color: 'white', mb: 2 }}
            >
              {isLlmLoading ? <CircularProgress size={24} /> : (mode === 'rag' ? 'RAGに送信' : 'LLMに送信')}
            </Button>
          </form>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: '400px',
              overflow: 'auto',
              bgcolor: 'rgba(255, 255, 255, 0.5)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {chatHistory.map((message, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
                  mb: 2
                }}
              >
                <Box
                  sx={{
                    maxWidth: '70%',
                    bgcolor: message.role === 'user' ? 'primary.light' : 'secondary.light',
                    borderRadius: 2,
                    p: 2,
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  {message.role === 'assistant' && (
                    <SmartToyIcon sx={{ mr: 1, color: 'secondary.main' }} />
                  )}
                  <Box>
                    <Typography variant="subtitle2" color="black" gutterBottom>
                      {message.role === 'user' ? 'You' : 'AI'}
                    </Typography>
                    <Markdown options={{
                      overrides: {
                        p: { component: Typography, props: { color: 'black' } }
                      }
                    }}>
                      {message.content}
                    </Markdown>
                  </Box>
                  {message.role === 'user' && (
                    <PersonIcon sx={{ ml: 1, color: 'primary.main' }} />
                  )}
                </Box>
              </Box>
            ))}
          </Paper>
        </>
      )}
    </>
  )}
</Box>
</Grid>
</Grid>
</Container>
);
};

export default App;
