import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

interface ModeSelectorProps {
  mode: 'llm' | 'rag' | 'test';
  setMode: (mode: 'llm' | 'rag' | 'test') => void;
  startNewSession: () => void;
}

const ModeSelector: React.FC<ModeSelectorProps> = ({ mode, setMode, startNewSession }) => {
  const handleModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: 'llm' | 'rag' | 'test' | null
  ) => {
    if (newMode !== null) {
      setMode(newMode);
      startNewSession();
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        モード選択:
      </Typography>
      <ToggleButtonGroup
        value={mode}
        exclusive
        onChange={handleModeChange}
        aria-label="mode selection"
      >
        <ToggleButton value="llm" aria-label="LLM mode">
          LLM
        </ToggleButton>
        <ToggleButton value="rag" aria-label="RAG mode">
          RAG
        </ToggleButton>
        <ToggleButton value="test" aria-label="Test mode">
          Test
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ModeSelector;
