import { llmApi, ragApi } from '../api';

interface ChatServiceParams {
  input: string;
  temperature: number;
  maxTokens: number;
  model: string;
  topP: number;
  sessionId: string;
  mode: 'llm' | 'rag' | 'test';
}

export const sendChatMessage = async (params: ChatServiceParams, token: string) => {
  if (params.mode === 'test') {
    throw new Error('テストモードはsendChatMessageではサポートされていません。');
  }
  const apiFunction = params.mode === 'rag' ? ragApi : llmApi;
  const result = await apiFunction({
    input: params.input,
    temperature: params.temperature,
    max_tokens: params.maxTokens,
    bedrock_model_id: params.model,
    top_p: params.topP,
    sessionId: params.sessionId
  }, token);

  if (result && result.answer) {
    return result.answer;
  } else {
    throw new Error('Unexpected response format');
  }
};
